import axios from 'axios'
import _ from 'lodash'
import { MessageBox, Message } from 'element-ui'
import store from '@/store'
import { getToken } from '@/utils/auth'

try {
  const configElement = document.getElementById('config')
  var config = JSON.parse(configElement.innerHTML)
  config.baseApi = (config.baseApi) ? config.baseApi : '/api-manager'
} catch (error) {
  Message({
    message: error.message,
    type: 'error',
    duration: 5 * 1000
  })
}

// create an axios instance
const service = axios.create({
  baseURL: config.baseApi, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 0 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent
    // config.headers.Accept = 'application/json'
    if (store.getters.token) {
      // let each request carry token
      // ['X-Token'] is a custom headers key
      // please modify it according to the actual situation
      config.headers.Authorization = 'Bearer ' + getToken()
    }
    return config
  },
  errorResponse
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */
  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    // Defaul data response Axios
    return response.data
  },
  errorResponse
)

function errorResponse (error) {
  if (error.response.status === 401) {
    // to re-login
    MessageBox.confirm('You have been logged out, you can cancel to stay on this page, or log in again', 'Confirm logout', {
      confirmButtonText: 'Re-Login',
      cancelButtonText: 'Cancel',
      type: 'warning'
    }).then(() => {
      store.dispatch('user/resetToken').then(() => {
        location.reload()
      })
    })
  } else if (_.inRange(error.response.status, 499, 600)) {
    Message({
      message: error.response.data.message,
      type: 'error',
      duration: 3 * 1000,
      showClose: true
    })

    return false
  }
  return Promise.reject(error)
}

export default service
