import Vue from 'vue'
import Router from 'vue-router'

/* Layout */
import Layout from '@/layout'

Vue.use(Router)

/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin','editor']    control the page roles (you can set multiple roles)
    title: 'title'               the name show in sidebar and breadcrumb (recommend set)
    icon: 'svg-name'/'el-icon-x' the icon show in the sidebar
    breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
 */

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */

export const constantRoutes = [

  {
    path: '/login',
    component: () => import('@/views/login/index'),
    hidden: true
  },

  {
    path: '/404',
    component: () => import('@/views/404'),
    hidden: true
  },

  // {
  //   path: '/',
  //   redirect: '/dashboard',
  //   component: Layout,
  //   children: [
  //     {
  //       path: 'dashboard',
  //       name: 'dashboard',
  //       component: () => import('@/views/dashboard/index'),
  //       meta: { title: 'Dashboard', icon: 'el-icon-s-data' }
  //     }
  //   ]
  // },
  {
    // path: '/users',
    path: '/',
    redirect: '/users',
    component: Layout,
    children: [
      {
        path: 'users',
        name: 'users',
        component: () => import('@/views/users/index'),
        meta: { title: 'Usuarios', icon: 'el-icon-user' }
      },
      {
        path: 'users/edit/:_id',
        name: 'users.edit',
        component: () => import('@/views/users/edit'),
        meta: {
          title: 'Usuarios / Editar usuario'
        },
        hidden: true,
        activeMenu: '/users/edit'
      },
      {
        path: 'users/create',
        name: 'users.create',
        component: () => import('@/views/users/create'),
        meta: {
          title: 'Usuarios / Registrar usuario'
        },
        hidden: true
        // breadcrumb: false
        // activeMenu: 'users'
        // alwaysShow: true
      }
    ]
  },
  {
    path: '/about',
    component: Layout,
    children: [
      {
        path: '',
        name: 'about',
        component: () => import('@/views/about/index'),
        meta: { title: 'Conoce tu empresa', icon: 'el-icon-office-building' }
      },
      {
        path: 'edit/:_id',
        name: 'about.edit',
        component: () => import('@/views/about/edit'),
        meta: {
          title: 'Conoce tu empresa / Editar'
        },
        hidden: true
      },
      {
        path: 'create',
        name: 'about.create',
        component: () => import('@/views/about/create'),
        meta: {
          title: 'Conoce tu empresa / Crear'
        },
        hidden: true
      }
    ]
  },
  {
    path: '/home',
    component: Layout,
    redirect: '/home',
    name: 'home-index',
    meta: {
      title: 'Home',
      icon: 'el-icon-connection'
    },
    children: [
      {
        path: 'sections',
        name: 'sections',
        component: () => import('@/views/sections/index'),
        meta: { title: 'Secciones' }
      },
      {
        path: 'sections/edit/:_id',
        name: 'sections.edit',
        component: () => import('@/views/sections/edit'),
        meta: {
          title: 'Editar seccion'
        },
        hidden: true,
        activeMenu: '/sections/edit/'
      },
      {
        path: 'sections/create',
        name: 'sections.create',
        component: () => import('@/views/sections/create'),
        meta: {
          title: 'Crear seccion'
        },
        hidden: true
      },
      {
        path: 'coverpage',
        name: 'coverpage',
        component: () => import('@/views/coverpage/index'),
        meta: { title: 'Portada' }
      },
      {
        path: 'coverpage/edit/:_id',
        name: 'coverpage.edit',
        component: () => import('@/views/coverpage/edit'),
        meta: {
          title: 'Editar portada'
        },
        hidden: true
      },
      {
        path: 'coverpage/create',
        name: 'coverpage.create',
        component: () => import('@/views/coverpage/create'),
        meta: {
          title: 'Crear portada'
        },
        hidden: true
      }
    ]
  },
  // Notifications
  {
    path: '/messages-group',
    component: Layout,
    redirect: '/messages-group',
    name: 'messages-index',
    meta: {
      title: 'Mensajes',
      icon: 'el-icon-message'
    },
    children: [
      {
        path: '',
        name: 'messages-group',
        component: () => import('@/views/messages/group'),
        meta: { title: 'Grupo de mensajes' }
      },
      {
        path: 'create',
        name: 'messages-group.create',
        component: () => import('@/views/messages/create'),
        meta: { title: 'Crear mensaje' },
        hidden: true
      },
      {
        path: 'message',
        name: 'messages',
        component: () => import('@/views/messages/index'),
        meta: { title: 'Mensajes' }
        // hidden: true
      }
    ]
  },
  {
    path: '/news',
    component: Layout,
    children: [
      {
        path: '',
        name: 'news',
        component: () => import('@/views/news/index'),
        meta: { title: 'Noticias', icon: 'el-icon-news' }
      },
      {
        path: 'edit/:_id',
        name: 'news.edit',
        component: () => import('@/views/news/edit'),
        meta: {
          title: 'Noticias / Editar noticia'
        },
        hidden: true
      },
      {
        path: 'create',
        name: 'news.create',
        component: () => import('@/views/news/create'),
        meta: {
          title: 'Noticias / Crear noticia'
        },
        hidden: true
      }
    ]
  },
  {
    path: '/releases',
    component: Layout,
    children: [
      {
        path: '',
        name: 'releases',
        component: () => import('@/views/releases/index'),
        meta: { title: 'Comunicados', icon: 'el-icon-document' }
      },
      {
        path: 'edit/:_id',
        name: 'releases.edit',
        component: () => import('@/views/releases/edit'),
        meta: {
          title: 'Comunicados / Editar comunicado'
        },
        hidden: true
      },
      {
        path: 'create',
        name: 'releases.create',
        component: () => import('@/views/releases/create'),
        meta: {
          title: 'Comunicados / Crear comunicado'
        },
        hidden: true
      }
    ]
  },
  {
    path: '/video',
    component: Layout,
    children: [
      {
        path: '',
        name: 'video',
        component: () => import('@/views/video/index'),
        meta: { title: 'Videoteca', icon: 'el-icon-video-camera' }
      },
      {
        path: 'edit/:_id',
        name: 'video.edit',
        component: () => import('@/views/video/edit'),
        meta: {
          title: 'Videoteca / Editar Video'
        },
        hidden: true
      },
      {
        path: 'create',
        name: 'video.create',
        component: () => import('@/views/video/create'),
        meta: {
          title: 'Videoteca / Crear Video'
        },
        hidden: true
      }
    ]
  },
  {
    path: '/courses',
    component: Layout,
    // redirect: '/courses',
    name: 'courses-index',
    meta: {
      title: 'Cursos',
      icon: 'el-icon-monitor'
    },
    children: [
      {
        path: 'courses',
        name: 'courses',
        component: () => import('@/views/courses/index'),
        meta: { title: 'Cursos' }
      },
      {
        path: 'create-courses',
        name: 'courses.create',
        component: () => import('@/views/courses/create'),
        meta: { title: 'Crear curso' },
        hidden: true
      },
      {
        path: 'edit-courses/:_id',
        name: 'courses.edit',
        component: () => import('@/views/courses/edit'),
        meta: { title: 'Editar curso' },
        hidden: true
      },
      {
        path: 'resources',
        name: 'resources',
        component: () => import('@/views/resources/index'),
        meta: { title: 'Recursos' }
      },
      {
        path: 'create-html',
        name: 'resources.create.html',
        component: () => import('@/views/resources/create-html'),
        meta: { title: 'Crear recurso HTML' },
        hidden: true
      },
      {
        path: 'create-pdf',
        name: 'resources.create.pdf',
        component: () => import('@/views/resources/create-pdf'),
        meta: { title: 'Crear recurso PDF' },
        hidden: true
      },
      {
        path: 'create-audio',
        name: 'resources.create.audio',
        component: () => import('@/views/resources/create-audio'),
        meta: { title: 'Crear recurso audio' },
        hidden: true
      },
      {
        path: 'create-video',
        name: 'resources.create.video',
        component: () => import('@/views/resources/create-video'),
        meta: { title: 'Crear recurso video' },
        hidden: true
      },
      {
        path: 'edit-resources/:_id',
        name: 'resources.edit',
        component: () => import('@/views/resources/edit'),
        meta: { title: 'Editar recursos' },
        hidden: true
      },
      {
        path: 'progress',
        name: 'progress',
        component: () => import('@/views/progress/index'),
        meta: { title: 'Progreso Cursos' },
        props: false
      }
    ]
  },
  {
    path: '/polls',
    component: Layout,
    redirect: '/polls',
    name: 'polls-index',
    meta: {
      title: 'Encuestas',
      icon: 'el-icon-tickets'
    },
    children: [
      {
        path: 'surveys',
        name: 'surveys',
        component: () => import('@/views/surveys/index'),
        meta: { title: 'Cuestionario' }
      },
      {
        path: 'create',
        name: 'surveys.create',
        component: () => import('@/views/surveys/create'),
        meta: { title: 'Crear cuestionario' },
        hidden: true
      },
      {
        path: 'edit/:_id',
        name: 'surveys.edit',
        component: () => import('@/views/surveys/edit'),
        meta: { title: 'Editar cuestionario' },
        hidden: true
      },
      {
        path: 'participation',
        name: 'participation',
        component: () => import('@/views/participation/index'),
        meta: { title: 'Participación' },
        props: false
      }
    ]
  },
  {
    path: '/mailbox',
    component: Layout,
    children: [
      {
        path: '',
        name: 'mailbox',
        component: () => import('@/views/mailbox/index'),
        meta: { title: 'Buzón', icon: 'el-icon-postcard' }
      }
    ]
  },
  {
    path: '/birthday',
    component: Layout,
    children: [
      {
        path: '',
        name: 'birthday',
        component: () => import('@/views/birthday/index'),
        meta: { title: 'Cumpleaños', icon: 'el-icon-magic-stick' }
      }
    ]
  },
  {
    path: '/extra',
    component: Layout,
    redirect: '/extra',
    name: 'extra-index',
    meta: {
      title: 'Extras',
      icon: 'el-icon-files'
    },
    children: [
      {
        path: 'image',
        name: 'upload.image',
        component: () => import('@/views/uploadImage/index'),
        meta: { title: 'Cargar imagen' }
      },
      {
        path: 'file',
        name: 'upload.file',
        component: () => import('@/views/uploadFile/index'),
        meta: { title: 'Cargar archivo' }
      }
    ]
  },
  {
    path: '/admin',
    component: Layout,
    children: [
      {
        path: '',
        name: 'admin',
        component: () => import('@/views/admin/index'),
        meta: { title: 'Admin', icon: 'el-icon-s-custom' }
      },
      {
        path: 'edit/:_id',
        name: 'admin.edit',
        component: () => import('@/views/admin/edit'),
        meta: {
          title: 'Editar Admin'
        },
        hidden: true
      },
      {
        path: 'create',
        name: 'admin.create',
        component: () => import('@/views/admin/create'),
        meta: {
          title: 'Crear Admin'
        },
        hidden: true
      }
    ]
  },
  {
    path: 'link',
    component: Layout,
    children: [
      {
        path: 'https://webdecero.com',
        meta: { title: 'Webdecero', icon: 'link' }
      }
    ]
  },
  { path: '*', component: Layout, redirect: '/' }
  // { path: '*', component: Layout, redirect: '/', hidden: true }
]

const createRouter = () => new Router({
  mode: 'history', // require service support
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes

})

const router = createRouter()

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter () {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}

export default router
